import { global } from "../constants";

export const data = {
    banner: {
        background: global.banner + "1.jpg",
        title: "FAQ",
        crumb: "FAQ"
    },
    faq: {
        title: "FAQs",
        subtitle: "Questions Fréquemment Posées",
        description: "",
        faqs: [
            {
                que: "Qu'est-ce que FIZAK ?",
                ans: "FIZAK est une entreprise basée à Agadir, au Maroc, spécialisée dans les services d'importation et d'exportation. Nous proposons une large gamme de services liés à l'importation et à l'exportation de divers produits et marchandises entre différents pays, notamment le commerce de fruits et de légumes"
            },
            {
                que: "Quels types de produits FIZAK commercialise-t-elle ?",
                ans: "FIZAK est impliquée dans l'importation et l'exportation de divers produits, y compris, mais sans s'y limiter, les fruits et les légumes"
            },
            {
                que: "Est-ce que FIZAK propose des services de distribution internationale ?",
                ans: "Oui, FIZAK propose des services de distribution internationale pour des produits divers. Nous avons la capacité de distribuer des produits à l'échelle internationale pour répondre aux besoins de nos clients"
            },
            {
                que: "Quels sont les services principaux proposés par FIZAK ?",
                ans: "FIZAK excelle dans les domaines de l'importation, de l'exportation, du commerce et des services de négociation pour une gamme diversifiée de produits. Nous nous engageons également dans la représentation commerciale par différents moyens. De plus, nous pouvons vous assister dans l'acquisition, la fourniture, la présentation et la concession de produits et services, parmi d'autres opérations connexes"
            },
            {
                que: "Pouvez-vous fournir des informations sur l'importation et l'exportation vers différents pays ?",
                ans: "Absolument, FIZAK propose une expertise en commerce international avec une focalisation sur différents pays, y compris le Maroc, la France, les Pays-Bas, le Royaume-Uni, l'Italie, l'Espagne, et bien d'autres. Que vous cherchiez des informations sur le commerce avec des pays spécifiques ou que vous souhaitiez bénéficier d'une expertise en commerce international, nous sommes à votre disposition pour vous fournir des solutions sur mesure. N'hésitez pas à nous contacter"
            },
        ]
    }
}
