import { data } from "../../../globals/data/product-details-fr";
import React from 'react';
import { useParams } from 'react-router-dom';

export default function SectionServiceContentFr(props) {
  const mois = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  
 
  
  
 
  const { type } = useParams();
  const fruits = data[type];

      const tableStyle = {
        fontFamily: 'Arial, Helvetica, sans-serif',
        borderCollapse: 'collapse',
        width: '100%',
        
      };
  
      const cellStyle = {
        border: '1px solid black',
        padding: '8px',
        textAlign: 'center',
        
      };
  
      const headerCellStyle = {
        padding: '12px',
        backgroundColor: 'grey',
        color: 'white',
        border: '1px solid black',
        padding: '8px',
        textAlign: 'center',
      };
    return (
        <>
            <div className="service-category-title">
                <div className="service-category-media"><img src={fruits.icon} alt="#" /></div>
                <h2>{fruits.title}</h2>
            </div>
            <div className="wt-media">
                <img  src={fruits.image} alt="#" />
            </div>
           
            <p class="description-text">
                {fruits.first_paragraph}
            </p>
            <p>
                 {fruits.seconde_paragraph}
            </p>
            
       
            <div>
        <h3>Calendrier de Récolte des Fruits</h3>
        <br />
        <table style={tableStyle} id="fruit-table">
          <thead>
            <tr>
              <th style={headerCellStyle}>Fruits</th>
              {mois.map((moisFr, index) => (
                <th key={index} style={headerCellStyle}>
                  {moisFr}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fruits.products.map((fruit, fruitIndex) => (
              <tr key={fruitIndex}>
                <td className="tw-24" style={cellStyle}>{fruit.title}</td>
                {fruit.availability.map((available, monthIndex) => (
                  <td key={monthIndex} style={{ backgroundColor: available ? 'orange' : 'white', border: '1px solid black' }}>
                  &nbsp;{/* Contenu de la cellule */}
                </td>
                
                
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        </>
    )
}