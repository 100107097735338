import { global } from "../constants";

export const data = {
  services: {
    title: "Nos services",
    subtitle:
      " NOUS FOURNISSONS À NOS CLIENTS DES PRODUITS ALIMENTAIRES SAINS ET FRAIS",
    description:
      "C'est l'une des voies les plus simples pour entrer dans le commerce mondial, et l'importation et l'exportation offrent d'énormes opportunités",
    services: [
      {
        icon: global.icons + "orange.png",
        title: "AGRUMES",
        serial: "01",
        link: "citrus",
        // description: "Import and export of all food products, seafood, fruits, vegetables, supplies, materials, accessories, machinery, vehicles, equipment, and articles."
      },
      {
        icon: global.icons + "tomate.png",
        title: "TOMATES",
        serial: "02",
        link: "tomatoes",
      },
      {
        icon: global.icons + "fraise.png",
        title: "FRUITS TENDRES",
        serial: "03",
        link: "fraise",
      },
      // {
      //     icon: global.icons + "banane.png",
      //     title: "BANANES",
      //     serial: "04",
      //     description: "Facilitating the buying and selling of diverse products, with expertise in effective marketing strategies and transaction management."
      // },
      {
        icon: global.icons + "fruit.png",
        title: "AUTRE FRUITS",
        serial: "04",
        link: "fruit",
      },
      {
        icon: global.icons + "vegetable.png",
        title: "LÉGUMES",
        serial: "05",
        link: "vegetable",
      },
      {
        icon: global.icons + "fish.jpg",
        title: "POISSONS",
        serial: "06",
        link: "fish"
    },
    ],
  },
  whychooseus: {
    background: global.back + "bg-1.jpg",
    title: "Pourquoi Nous Choisir ?",
    subtitle: " ",
    description: {
      short:
        "Notre politique garantit la qualité et la sécurité alimentaire de nos produits",
      long: "Nous nous engageons à fournir des produits et des services conformes à la réglementation et aux exigences des clients, tout en garantissant la qualité, la sécurité alimentaire et la traçabilité. Notre engagement envers l'environnement et les pratiques durables est primordial, et nous cherchons constamment à nous améliorer. Grâce à des programmes de formation personnalisés, nous maintenons une main-d'œuvre compétitive",
    },
    pic1: global.whyChoose + "pic1.jpg",
    pic2: global.whyChoose + "pic2.jpg",
    help: {
      icon: global.whyChoose + "qt-icon.png",
      number: "(+31) 611 361 785",
    },
  },
  booking: {
    background: global.booking + "bg-map.png",
    image: global.booking + "pic2.png",
    imageBack: global.booking + "truck-book.jpg",
    title: "OBTENIR UN DEVIS",
    subtitle: "Indiquez-nous vos besoins, et nous vous fournirons un devis",
    description:
      "Veuillez compléter le formulaire ci-dessous. Nos experts en transport de marchandises vous contacteront pour discuter d'un devis tarifaire. Les informations fournies nous permettront de mieux préparer notre prise de contact avec vous.",
    form: {
      quote: global.booking + "icon1.png",
      track: global.booking + "icon2.png",
    },
  },

  clients: [
    global.logo + "w1.png",
    global.logo + "w2.png",
    global.logo + "w3.png",
    global.logo + "w4.png",
    global.logo + "w5.png",
    global.logo + "w1.png",
    global.logo + "w2.png",
    global.logo + "w3.png",
    global.logo + "w4.png",
    global.logo + "w5.png",
  ],

  whychoosus: {
    title: "Why Choose Us",
    subtitle: "We Are Professional Logistics & cargo Agency",
    description:
      "Sed ut perspiciatis unde omnis iste natus error volup tatem accusantium dolorem que laudantium, totam inventore.",
    branches: "295+",
    since: "1996",
    specifications: [
      "Go beyond logistics, make the world go round and revolutionize business.",
      "Logistics through innovation, dedication, and technology. ready, set, done.",
      "We take pride in serving our customers safely. together with passion.",
      "Imagination what we can easily see is only a small percentage.",
      "Quality never goes out of style. safety, quality, professionalism.",
      "The quality shows in every move we make where business lives.",
    ],
  },

  estimation: {
    background: global.back + "bg-5.jpg",
    title: "Estimation",
    subtitle: "Has a wide range of solutions",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the  when an printer took.",
    solutions: [
      {
        serial: "01",
        title: "Solutions and specialized expertise",
        subtitle:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service",
      },
      {
        serial: "02",
        title: "Multiple warehouses",
        subtitle:
          "We provide multiple drop off and pickup locations so you don't have to worry. And you should not face any kind...",
      },
      {
        serial: "03",
        title: "Tracking made easy",
        subtitle:
          "A tracking number for the entire process. so that you can find the exact position. it will help you",
      },
    ],
  },

  testimonials: {
    title: "Testimonials",
    subtitle: "Client Says",
    quotes: [
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Peter Len",
        designation: "Web Designer",
        image: global.testim + "pic1.jpg",
      },
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Giselle",
        designation: "Developer",
        image: global.testim + "pic2.jpg",
      },
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Josephine",
        designation: "Web Designer",
        image: global.testim + "pic3.jpg",
      },
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Penelope",
        designation: "Developer",
        image: global.testim + "pic4.jpg",
      },
    ],
  },

  blogs: {
    title: "Our Blogs",
    subtitle: "Recent news & events",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    blogs: [
      {
        image: global.blog1 + "bg1.jpg",
        day: "02",
        month: "Aug",
        title:
          "At the end of the day, going forward, a new normal that has evolved from.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
      {
        image: global.blog2 + "l-1.jpg",
        day: "08",
        month: "Aug",
        title:
          "How all this mistaken idea denouncing pleasure and praising pain was.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
      {
        image: global.blog1 + "bg3.jpg",
        day: "21",
        month: "Aug",
        title: "Going forward, a new normal that has evolved from generation.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
    ],
  },

  approach: {
    title: "Company Approch",
    subtitle: "Reliable Logistic & Transport Solutions",
    yearsOfExperience: "35",
    specifications: [
      {
        icon: global.icons + "c-pic1.png",
        count: "53",
        title: "Professional Staff",
      },
      {
        icon: global.icons + "c-pic2.png",
        count: "250",
        title: "On Time Delievery",
      },
      {
        icon: global.icons + "c-pic3.png",
        count: "122",
        title: "Technology and Media",
      },
    ],
  },

  projects: {
    title: "Projects",
    subtitle: "Featured Projects",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    projects: [
      {
        image: global.projects + "1.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge",
      },
      {
        image: global.projects + "2.jpg",
        title: "Logistics, Analytics",
        subtitle: "Minimize Manufacturing",
        caption: "Nh-16 Highway Bridge",
      },
      {
        image: global.projects + "3.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge",
      },
      {
        image: global.projects + "4.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge",
      },
      {
        image: global.projects + "5.jpg",
        title: "Logistics, Analytics",
        subtitle: "Minimize Manufacturing",
        caption: "Nh-16 Highway Bridge",
      },
      {
        image: global.projects + "6.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge",
      },
    ],
  },

  plans: {
    title: "Our Plan",
    subtitle: "The right price for you whoever you are",
    plans: [
      {
        type: "Startup",
        price: "90$",
        benifits: [
          "Additional",
          "Warehouing",
          "Custos Borkerage",
          "Unlimited Transfer",
        ],
      },
      {
        type: "Standard",
        price: "150$",
        benifits: [
          "Additional",
          "Warehouing",
          "Custos Borkerage",
          "Unlimited Transfer",
        ],
      },
      {
        type: "Business",
        price: "260$",
        benifits: [
          "Additional",
          "Warehouing",
          "Custos Borkerage",
          "Unlimited Transfer",
        ],
      },
    ],
  },
};
