import { global } from "../constants";

export const data = {
    banner: {
        background: global.banner + "1.jpg",
        title: "À propos de nous",
        crumb: "À propos de nous"
    },

    whatwedo: {
        title: "FIZAK",
        subtitle: "Nous nous spécialisons dans l'importation et l'exportation de fruits et de légumes",
        description: "Nous vous souhaitons la bienvenue au sein de notre entreprise, votre partenaire de confiance pour les services d'importation et d'exportation dans le secteur des fruits et légumes à l'échelle mondiale. Forts de notre longue expérience dans ce domaine, nous nous engageons à satisfaire pleinement vos besoins et vos demandes",
        image: global.icons + "img1.jpg",
        works: [
            {
                icon: global.icons + "pic1.png",
                serial: "01.",
                title: "Commerce International",
                description: "Nous nous spécialisons dans l'importation et l'exportation d'une large gamme de fruits et de légumes, garantissant des produits de haute qualité livrés directement chez vous"
            },
            {
                icon: global.icons + "pic3.png",
                serial: "02.",
                title: "Distribution",
                description: "Notre réseau de distribution mondial garantit que nos produits frais vous parviennent de manière efficace et ponctuelle, où que vous soyez"
            },
            {
                icon: global.icons + "pic2.png",
                serial: "03.",
                title: "Ventes et Marketing",
                description: "Nous excellons dans la commercialisation et la vente de divers produits de fruits et de légumes, reliant les producteurs et les consommateurs à l'échelle mondiale"
            }
        ]
    },
    
    whychoosus: {
        title: "Pourquoi nous choisir ?",
        subtitle: "",
        description: "NOTRE POLITIQUE GARANTIT LA QUALITÉ ET LA SÉCURITÉ ALIMENTAIRE DE NOS PRODUITS",
        branches: "",
        since: "Since 20XX", 
        specifications: [
            "Large Gamme de Fruits et Légumes Frais",
            "Respect Strict des Normes de Qualité",
            "Mesures Complètes de Sécurité Alimentaire",
            "Pratiques Écologiquement Durables",
            "Réseau de Distribution Mondial",
            "Approche Axée sur le Client"
        ]
    },

    achivements: [
        {
            count: "12",
            title: "Pays couverts"
        },
        {
            count: "1500",
            title: "Tonnage Annuel"
        },
        {
            count: "60",
            title: "Membres d'Équipe Dévoués"
        }
        
    ],

    testimonials: {
        title: "Respected",
        subtitle: "Clients & partners",
        clients: [
            global.logo + "/w1.png",
            global.logo + "/w2.png",
            global.logo + "/w3.png",
            global.logo + "/w4.png",
            global.logo + "/w5.png",
            global.logo + "/w6.png",
        ],
        partners: [
            {
                pic: global.testim + "pic1.jpg",
                quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
                name: "Peter Len",
                designation: "Web Designer"
            },
            {
                pic: global.testim + "pic2.jpg",
                quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
                name: "Giselle",
                designation: "Developer"
            },
            {
                pic: global.testim + "pic3.jpg",
                quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
                name: "Josephine",
                designation: "Web Designer"
            },
            {
                pic: global.testim + "pic4.jpg",
                quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
                name: "Penelope",
                designation: "Developer"
            }
        ]
    },

    blogs: {
        title: "Our Blogs",
        subtitle: "Recent news & events",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
        blogs: [
            {
                image: global.blog1 + "bg1.jpg",
                day: "02",
                month: "Aug",
                title: "At the end of the day, going forward, a new normal that has evolved from.",
                description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
            },
            {
                image: global.blog2 + "l-1.jpg",
                day: "08",
                month: "Aug",
                title: "How all this mistaken idea denouncing pleasure and praising pain was.",
                description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
            },
            {
                image: global.blog1 + "bg3.jpg",
                day: "21",
                month: "Aug",
                title: "Going forward, a new normal that has evolved from generation.",
                description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
            }
        ]
    },

    approach: {
        title: "Company Approch",
        subtitle: "Reliable Logistic & Transport Solutions",
        yearsOfExperience: "35",
        specifications: [
            {
                icon: global.icons + "c-pic1.png",
                count: "195",
                title: "Professional Staff"
            },
            {
                icon: global.icons + "c-pic2.png",
                count: "250",
                title: "On Time Delievery"
            },
            {
                icon: global.icons + "c-pic3.png",
                count: "350",
                title: "Technology and Media"
            }
        ]
    }
}