import { useState } from "react";
export default function SectionBooking1({ _data }) {
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(formData);

    const apiUrl = "http://fizak.net/backend/public/api/devis"; // Replace with your API endpoint

    // Make an HTTP POST request to the API using fetch
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
      body: JSON.stringify(formData), // Convert the form data to JSON format
    })
      .then((response) => {
        console.log("response");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setFormData({
          nom: "",
          company: "",
          email: "",
          telephone: "",
          sujet: "",
          DepAdresse: "",
          DelAdresse: "",
          message: "",
        });
        return response.json(); // Parse the response body as JSON
      })
      .catch((error) => {
        console.error("Error sending form data:", error);
      });
  };

  let freight = ["Freight Type", "..."];
  let incoterms = ["Incoterms", "..."];
  let shipments = ["Shipment Type", "Road", "Train", "Air", "Sea"];
  const [formData, setFormData] = useState({
    nom: "",
    company: "",
    email: "",
    telephone: "",
    sujet: "",
    DepAdresse: "",
    DelAdresse: "",
    message: "",
  });
  return (
    <div
      className="section-full p-t120 p-b90 site-bg-gray tw-booking-area tw-booking-area-bg-wrap"
      style={{ backgroundImage: `url(${_data.background})` }}
    >
      <div
        className="tw-booking-area-bg"
        style={{ backgroundImage: `url(${_data.imageBack})` }}
      ></div>

      <div className="container">
        {/* <!-- TITLE START--> */}
        <div className="section-head center wt-small-separator-outer">
          <div className="wt-small-separator site-text-primary">
            <div>{_data.title}</div>
          </div>
          <h2 className="wt-title">{_data.subtitle}</h2>
          <p className="section-head-text">{_data.description}</p>
        </div>
        {/* <!-- TITLE END--> */}
      </div>

      <div className="container">
        <div className="tw-booking-section">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-12">
              <div className="tw-booking-media">
                <div className="media">
                  <img src={_data.image} alt="#" />
                </div>
              </div>
            </div>

            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tw-booking-form">
                <div className="row booking-tab-container">
                  <div className="col-lg-10 col-md-10 booking-tab">
                    {/* <!-- flight section --> */}
                    <div className="booking-tab-content active">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    nom: e.target.value,
                                  })
                                }
                                value={formData.nom}
                                name="nom"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Nom"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    company: e.target.value,
                                  })
                                }
                                value={formData.company}
                                name="company"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Entreprise"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    email: e.target.value,
                                  })
                                }
                                value={formData.email}
                                name="email"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    telephone: e.target.value,
                                  })
                                }
                                value={formData.telephone}
                                name="telephone"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Numéro de télételephone"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    sujet: e.target.value,
                                  })
                                }
                                value={formData.sujet}
                                name="sujet"
                                type="text"
                                required
                                className="form-control"
                                placeholder="sujet"
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12">
                            <div className="mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    message: e.target.value,
                                  })
                                }
                                value={formData.message}
                                name="message"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Détails de l'Expédition / Message"
                                style={{ height: "100px" }}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    DepAdresse: e.target.value,
                                  })
                                }
                                value={formData.DepAdresse}
                                name="DepAdresse"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Adresse de départ"
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    DelAdresse: e.target.value,
                                  })
                                }
                                value={formData.DelAdresse}
                                name="DelAdresse"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Adresse de Livraison"
                              />
                            </div>
                          </div>

                          {/* <div className="col-lg-3 col-md-3">
        <div className="mb-3">
            <input name="weight" type="text" required className="form-control" placeholder="Weight" />
        </div>
    </div> */}
                          {/* <div className="col-lg-3 col-md-3">
        <div className="mb-3">
            <input name="height" type="text" required className="form-control" placeholder="Height" />
        </div>
    </div> */}
                          {/* <div className="col-lg-3 col-md-3">
        <div className="mb-3">
            <input name="width" type="text" required className="form-control" placeholder="Width" />
        </div>
    </div> */}
                          {/* <div className="col-lg-3 col-md-3">
        <div className="mb-3">
            <input name="length" type="text" required className="form-control" placeholder="Length" />
        </div>
    </div> */}

                          <div className="col-lg-12 col-md-12">
                            <div className="tw-booking-footer">
                              <div className="tw-booking-footer-btn">
                                <button
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn-half site-button"
                                >
                                  <span>Envoyer</span>
                                  <em></em>
                                </button>
                              </div>
                              {/* <span className="tw-booking-footer-text">Quote</span> */}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
