import { useState } from "react";
export default function SectionBookServiceFr() {
  const handleSubmit = (e) => {
    e.preventDefault();

    // Define the API endpoint where you want to send the data
    const apiUrl = "http://fizak.net/backend/public/api/devis"; // Replace with your API endpoint

    // Make an HTTP POST request to the API using fetch
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
      body: JSON.stringify(formData), // Convert the form data to JSON format
    })
      .then((response) => {
        console.log("response");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response body as JSON
      })
      .then((data) => {
        // Handle a successful response from the API (e.g., show a success message)
        console.log("Form data sent successfully:", data);

        // Optionally, you can reset the form data after successful submission
        setFormData({
          nom: "",
          company: "",
          email: "",
          telephone: "",
          sujet: "",
          DepAdresse: "",
          DelAdresse: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error sending form data:", error);
      });
  };

  const [formData, setFormData] = useState({
    nom: "",
    company: "",
    email: "",
    telephone: "",
    sujet: "",
    DepAdresse: "",
    DelAdresse: "",
    message: "",
  });
  return (
    <>
      <h2 className="wt-title mt-4 mb-4">Réserver d'autres services</h2>

      <div className="tw-booking-form">
        <div className="row booking-tab-container">
          <div className="col-lg-10 col-md-10 booking-tab">
            {/* <!-- flight section --> */}
            <div className="booking-tab-content active">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <input
                        onChange={(e) =>
                          setFormData({ ...formData, nom: e.target.value })
                        }
                        value={formData.nom}
                        name="nom"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Nom"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <input
                        onChange={(e) =>
                          setFormData({ ...formData, company: e.target.value })
                        }
                        value={formData.company}
                        name="company"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Entreprise"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <input
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                        value={formData.email}
                        name="email"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <input
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            telephone: e.target.value,
                          })
                        }
                        value={formData.telephone}
                        name="telephone"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Numéro de télételephone"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="mb-3">
                      <input
                        onChange={(e) =>
                          setFormData({ ...formData, sujet: e.target.value })
                        }
                        value={formData.sujet}
                        name="sujet"
                        type="text"
                        required
                        className="form-control"
                        placeholder="sujet"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="mb-3">
                      <input
                        onChange={(e) =>
                          setFormData({ ...formData, message: e.target.value })
                        }
                        value={formData.message}
                        name="message"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Détails de l'Expédition / Message"
                        style={{ height: "100px" }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <input
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            DepAdresse: e.target.value,
                          })
                        }
                        value={formData.depart}
                        name="depart"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Adresse de départ"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <input
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            DelAdresse: e.target.value,
                          })
                        }
                        value={formData.DelAdresse}
                        name="DelAdresse"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Adresse de Livraison"
                      />
                    </div>
                  </div>

                  {/* <div className="col-lg-3 col-md-3">
                        <div className="mb-3">
                            <input name="weight" type="text" required className="form-control" placeholder="Weight" />
                        </div>
                    </div> */}
                  {/* <div className="col-lg-3 col-md-3">
                        <div className="mb-3">
                            <input name="height" type="text" required className="form-control" placeholder="Height" />
                        </div>
                    </div> */}
                  {/* <div className="col-lg-3 col-md-3">
                        <div className="mb-3">
                            <input name="width" type="text" required className="form-control" placeholder="Width" />
                        </div>
                    </div> */}
                  {/* <div className="col-lg-3 col-md-3">
                        <div className="mb-3">
                            <input name="length" type="text" required className="form-control" placeholder="Length" />
                        </div>
                    </div> */}

                  <div className="col-lg-12 col-md-12">
                    <div className="tw-booking-footer">
                      <div className="tw-booking-footer-btn">
                        <button
                          onClick={handleSubmit}
                          type="submit"
                          className="btn-half site-button"
                        >
                          <span>Envoyer</span>
                          <em></em>
                        </button>
                      </div>
                      {/* <span className="tw-booking-footer-text">Quote</span> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
