import { global } from "../constants";

export const data = {
    banner: {
        background: global.banner + "1.jpg",
        title: "FAQ",
        crumb: "FAQ"
    },
    faq: {
        title: "FAQs",
        subtitle: "Frequently Asked Questions ?",
        description: "",
        faqs: [
            {
                que: "What is FIZAK ?",
                ans: "FIZAK is a company based in Agadir, Morocco, specializing in providing import and export services. We offer a wide range of services related to the import and export of various products and commodities between different countries. These services include the trade of fruits and vegetables."
            },
            {
                que: "What types of products does FIZAK trade in ?",
                ans: "FIZAK is involved in the import and export of various products, including but not limited to fruits and vegetables."
            },
            {
                que: "Does FIZAK offer international distribution services ?",
                ans: "Yes, FIZAK provides international distribution services for a wide range of products. We have the capability to distribute products internationally to meet the needs of our clients."
            },
            {
                que: "What are the core services offered by FIZAK ?",
                ans: "FIZAK specializes in import, export, trade, and negotiation services for a diverse range of products. We also engage in commercial representation through various means. Additionally, we can assist with the acquisition, procurement, presentation, and concession of products and services, among other related operations."
            },
            {
                que: "Can you provide information about importing and exporting to various countries ?",
                ans: "Certainly, FIZAK offers expertise in international trade with a focus on various countries, including Morocco, France, Netherlands, UK, Italy, Spain, and more. Whether you have questions about trading with specific countries or need assistance with international trade in general, feel free to reach out to us for tailored solutions."
            },
        ]
    }
}
