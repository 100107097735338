/* eslint-disable jsx-a11y/iframe-has-title */
import { useState } from "react";
import Banner from "../../sections/common/banner";
import { data } from "../../../globals/data/contact-us";
import { publicUrlFor } from "../../../globals/constants";

export default function ContactPage() {
  const handleSubmit = (e) => {
    e.preventDefault();

    // Define the API endpoint where you want to send the data
    const apiUrl = "http://fizak.net/backend/public/api/contacts"; // Replace with your API endpoint

    // Make an HTTP POST request to the API using fetch
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
      body: JSON.stringify(formData), // Convert the form data to JSON format
    })
      .then((response) => {
        console.log("response");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response body as JSON
      })
      .then((data) => {
        // Handle a successful response from the API (e.g., show a success message)
        console.log("Form data sent successfully:", data);

        // Optionally, you can reset the form data after successful submission
        setFormData({
          nom: "",
          email: "",
          telephone: "",
          sujet: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error sending form data:", error);
      });
  };
  const [formData, setFormData] = useState({
    nom: "",

    email: "",
    telephone: "",
    sujet: "",

    message: "",
  });
  return (
    <>
      <Banner _data={data.banner} />

      <div className="section-full  p-t120 p-b120">
        <div className="section-content">
          <div className="container">
            <div className="gmap-outline mb-5">
              <div className="google-map">
                <div style={{ width: "100%" }}>
                  <iframe
                    height={460}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27520.703684876884!2d-9.600829323495372!3d30.433608316157525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3b616b7f0c3d1%3A0x8eb5ace4ec157f19!2sCit%C3%A9%20Al%20Mohammadi%2C%20Agadir%2080000!5e0!3m2!1sfr!2sma!4v1693322105228!5m2!1sfr!2sma"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="contact-one">
              {/* TITLE START*/}
              <div className="section-head left wt-small-separator-outer">
                <h2 className="wt-title m-b30">Send Us a Message</h2>
              </div>
              {/* TITLE END*/}
              {/* CONTACT FORM*/}
              <div className="contact-one-inner  site-bg-sky-blue-light">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="contact-form-outer">
                      <form
                        onSubmit={handleSubmit}
                        className="cons-contact-form"
                        method="post"
                        action="form-handler2.php"
                      >
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    nom: e.target.value,
                                  })
                                }
                                value={formData.nom}
                                name="nom"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    email: e.target.value,
                                  })
                                }
                                value={formData.email}
                                name="email"
                                type="text"
                                className="form-control"
                                required
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    telephone: e.target.value,
                                  })
                                }
                                value={formData.telephone}
                                name="telephone"
                                type="text"
                                className="form-control"
                                required
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group mb-3">
                              <input
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    sujet: e.target.value,
                                  })
                                }
                                value={formData.sujet}
                                name="sujet"
                                type="text"
                                className="form-control"
                                required
                                placeholder="Subject"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              <textarea
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    message: e.target.value,
                                  })
                                }
                                value={formData.message}
                                name="message"
                                className="form-control"
                                rows={3}
                                placeholder="Message"
                                defaultValue={""}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button
                              type="submit"
                              className="btn-half site-button"
                            >
                              <span>Send</span>
                              <em />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div
                      className="contact-info  site-bg-dark bg-no-repeat bg-bottom-left"
                      style={{
                        backgroundImage: `url(${publicUrlFor(
                          "/assets/images/background/contact-bg.png"
                        )})`,
                      }}
                    >
                      {/* TITLE START*/}
                      <div className="section-head-small white mb-4">
                        <h3 className="wt-title">Let's get in touch</h3>
                      </div>
                      {/* TITLE END*/}
                      <div className="contact-info-section">
                        <div className="c-info-column">
                          <div className="c-info-icon fa fa-map-marker">
                            <span />
                          </div>
                          <p>
                            M7 Building 24, Bayti Sakane Residence, Hay
                            Mohammadi, Agadir, Morocco.
                          </p>
                        </div>
                        <div className="c-info-column">
                          <div className="c-info-icon fa fa-mobile-phone custome-size">
                            <span />
                          </div>
                          <p>
                            <a href="tel:+31611361785">(+31) 611 361 785</a>
                          </p>
                          {/* <p><a href="tel:+216-761-8331">+2 900 234 3219</a></p> */}
                        </div>
                        <div className="c-info-column">
                          <div className="c-info-icon fa fa-envelope-o">
                            <span />
                          </div>
                          <p>contact@fizak.net</p>
                          {/* <p>support12@gmail.com</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
