import { useState } from "react";
import Loader from "./app/elements/loader";
import RootLayout from "./layouts/root-layout";
import RootLayoutFr from "./layouts/root-layout-fr";
import ScrollToTop from "./app/elements/ScrollToTop";
import { useLocation } from "react-router-dom";

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState("en");

  const toggleLanguageFr = () => {
    setCurrentLanguage("fr");
  };
  const toggleLanguageEn = () => {
    setCurrentLanguage("en");
  };
  const currentpath = useLocation().pathname;
  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  return (
    <>
      {isLoading && <Loader />}
      <ScrollToTop />
      {currentLanguage === "en" ? (
        <RootLayout
          toggleLanguage={toggleLanguageFr}
          currentLanguage={currentLanguage}
        />
      ) : (
        <RootLayoutFr
          toggleLanguage={toggleLanguageEn}
          currentLanguage={currentLanguage}
        />
      )}
    </>
  );
}
