
import SectionSlider4 from "../../sections/home/index4/section-slider4-fr"
import SectionServices2 from "../../sections/home/index2/section-services2-fr"
import SectionClients3 from "../../sections/home/index4/section-clients3"
import SectionBooking1 from "../../sections/home/index/section-booking1-fr"
import SectionWhyChooseUs1 from "../../sections/home/index/section-why-choose-us1-fr";
import SectionCompanyApproch1 from "../../sections/home/index/section-company-approch1"
import { data } from "../../../globals/data/home4-fr";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants"

function Home4PageFr() {

    useEffect(() => {
        loadScript("/assets/js/custom.js", true)
    })

    return (
        <>
            <SectionSlider4 />
            <SectionServices2 _data={data.services} />
            <SectionWhyChooseUs1 _data={data.whychooseus} />
            {/* <SectionCompanyApproch1 _data={data.approach} /> */}
            <SectionBooking1 _data={data.booking} />
            {/* <SectionClients3 _data={data.clients} /> */}
        </>
    )
}

export default Home4PageFr;