const publicUrl = process.env.PUBLIC_URL
const baseUrl = publicUrl
const appImages = baseUrl + "/assets/images/products/"
export const data = {
    citrus: {
        title: "Citrus",
        icon: appImages + "orange.png",
        icon_date: appImages + "orangedate.png",
        image: appImages + "orange.jpg",
        first_paragraph: "At our Moroccan Citrus Import and Export platform, we are dedicated to providing you with the freshest and finest citrus fruits that the bountiful Moroccan soil has to offer. With a rich history in citrus cultivation and an unwavering commitment to quality, we are your trusted partner in the global citrus trade. Whether you are a citrus enthusiast, a retailer, or a distributor, we have a wide array of products and services tailored to meet your specific needs.",
        seconde_paragraph: "",
        products: [
          
            {
                title: "Citron",
                availability: [true, true, true, true, true, true, false, false, false, false, true, true],
            },

            {
                title: "Clementine",
                availability: [true, true, false, false, false, false, false, false, false, false, true, true],
            },

            {
                title: "Mandarine AFOURAR",
                availability: [true, true, true, false, false, false, false, false, false, false, false, false],
            },

            {
                title: "Orange Navel",
                availability: [false, false, true, true, true, true, false, false, false, false, false, false],
            },
            {
                title: "Orange Morocco Late",
                availability: [false, false, false, false, true, true, true, true, false, false, false, false],
            },           
        ]
    },
    vegetable: {
        title: "Vegetables",
        icon: appImages + "poivre-vert.png",
        icon_date: appImages + "poivre-vert.png",
        image: appImages + "vegetable.jpg",
        first_paragraph: "At FIZAK, we are dedicated to offering an exceptional variety of top-quality vegetables for import and export. Our fresh vegetables are carefully cultivated in the fertile Moroccan soil, bathed in Mediterranean sunlight. Our commitment to quality and sustainability means that each vegetable we offer is an example of freshness, taste, and nutritional value. Whether you're a retailer, wholesaler, or distributor, our vegetables are the perfect choice to satisfy even the most discerning palates. From juicy tomatoes to crisp bell peppers, tender zucchinis to flavorful eggplants, we have a diverse range of vegetables that will add a touch of freshness and vitality to every dish. Explore our selection of premium Moroccan vegetables with FIZAK and discover culinary excellence in every bite",
        seconde_paragraph: "",
        products: [
          

            {
                title: "Bell Pepper",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },

            {
                title: "Flat bean",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },

            {
                title: "white / black zucchini",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },
            {
                title: "eggplant",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },  
            {
                title: "Green / Red Long Pepper",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },  
            {
                title: "Yellow Corno Blanco Pepper",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },  
            {
                title: "Hot Red / Green Pepper",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            }, 
            {
                title: "Peas",
                availability: [false, false, false, false, false,false, false, false, false, true, true, true],
            },  
            {
                title: "Cucumber",
                availability: [true, true, true, false, false,false, false, false, false, true, true, true],
            }, 
            {
                title: "Squash",
                availability: [false, false, true, true, true,true, true, true, false, false, false, false],
            },                
        ]
    },
    tomatoes: {
        title: "Tomatoes",
        icon: appImages + "tomate.png",
        icon_date: appImages + "tomate.png",
        image: appImages + "tomate.jpg",
        first_paragraph: "At FIZAK, we take pride in offering a diverse range of high-quality products , and one of our flagship offerings is fresh, succulent tomatoes. Morocco is renowned for its exceptional agricultural produce, and our tomatoes are no exception. Grown under the Mediterranean sun and nurtured by skilled local farmers, our tomatoes are bursting with flavor, vibrant in color, and packed with essential nutrients. Whether you're a wholesaler, distributor, or retailer, our tomatoes are a versatile ingredient that can elevate the culinary experience of your customers. From classic pasta sauces to refreshing salads, our premium tomatoes will not only meet but exceed your expectations. Explore the world of Moroccan tomatoes with FIZAK and experience the taste of excellence in every bite",
        seconde_paragraph: "",
        products: [
          
            {
                title: "Round tomatoes",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },
            {
                title: "Cherry tomato",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },  
                      
        ]
    },
    fraise: {
        title: "SOFT FRUITS",
        icon: appImages + "fraise.png",
        icon_date: appImages + "fraise.png",
        image: appImages + "fraise.jpg",
        first_paragraph: "At FIZAK, we specialize in bringing you a delectable array of soft fruits, handpicked from the lush orchards of Morocco, where the Mediterranean climate imparts a unique and delightful flavor to each piece of fruit. Our commitment to quality shines through in every sweet, juicy berry and tender, ripened fruit we offer. Whether you're a retailer, wholesaler, or distributor, our soft fruits are your passport to enhancing your product lineup with nature's sweet treasures. From vibrant strawberries that burst with summery goodness to the rich, sun-kissed apricots that offer a taste of the Mediterranean, our soft fruits are the embodiment of freshness and succulence. Elevate your culinary creations and captivate your customers with the irresistible allure of Moroccan soft fruits, available from FIZAK",
        seconde_paragraph: "",
        products: [
          
            {
                title: "Strawberry",
                availability: [true, true, true, true, true,true, false, false, false, false, true, true],
            },
            {
                title: "Raspberry",
                availability: [true, true, true, true, true,true, false, false, false, false, true, true],
            }, 
            {
                title: "Blackberry",
                availability: [false, false, false, true, true,true, false, false, false, false, false, false],
            },
            {
                title: "Blueberry",
                availability: [true, true, true, true, true,true, true, false, false, false, false, false],
            },  
                      
        ]
    },
    fruit: {
        title: "Other FRUITS",
        icon: appImages + "fruit.png",
        icon_date: appImages + "fruit.png",
        image: appImages + "fruit.jpg",
        first_paragraph: "At FIZAK, we are passionate about delivering a tantalizing assortment of fresh fruits that epitomize the essence of nature's sweetness and vitality. Our fruit selection is a symphony of flavors and colors, meticulously sourced and handpicked to ensure unparalleled taste and quality. From the thirst-quenching juiciness of a ripe watermelon to the sunny disposition of a golden yellow melon, our fruits embody the very essence of freshness and natural goodness. Explore our abundant collection and elevate your culinary creations with the irresistible allure of fresh fruits from around the globe",
        seconde_paragraph: "",
        products: [
          
            {
                title: "watermelon",
                availability: [false, false, true, true, true,true, true, true, false, false, false, false],
            },
            {
                title: "yellow melon",
                availability: [false, false, true, true, true,true, true, true, false, false, false, false],
            }, 
                                 
        ]
    },
}
