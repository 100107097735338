import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../globals/constants";

export default function Footer1Fr() {
  return (
    <>
      <footer className="footer-light">
        {/* NEWS LETTER SECTION START */}
        <div className="ftr-nw-ltr site-bg-white">
          <div className="ftr-nw-ltr-inner site-bg-primary">
            <div className="container">
              {/* <div className="ftr-nw-img">
                                <img src={publicUrlFor("/assets/images/news-l-bg.png")} alt="#" />
                            </div> */}
              <div className="ftr-nw-content">
                <div className="ftr-nw-title">
                  Abonnez-vous pour les offres et les actualités
                </div>
                <div className="ftr-nw-form">
                  <form>
                    <input
                      name="news-letter"
                      className="form-control"
                      placeholder="Votre Adresse Email"
                      type="text"
                    />
                    <button className="ftr-nw-subcribe-btn">
                      Abonnez-vous maintenant
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* NEWS LETTER SECTION END */}
        <div className="ftr-bg">
          {/* FOOTER BLOCKES START */}
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_about">
                    <div className="logo-footer clearfix">
                      <NavLink to="/index">
                        <img
                          src={publicUrlFor("/assets/images/logo-light.png")}
                          alt="#"
                        />
                      </NavLink>
                    </div>
                    <p>
                      FIZAK s'engage à assurer la protection des données
                      personnelles et la mise en place de mesures de sécurité
                      organisationnelles et techniques
                    </p>
                    <ul className="social-icons">
                      <li>
                        <a
                          href="https://www.facebook.com/fizakimportexport"
                          className="fa fa-facebook"
                        >
                          {" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href=" https://www.linkedin.com/in/fizakimportexport/?fbclid=IwAR1O9L4K4Geh0fsskQKYw6FO-ji0zSDRRAqWVZL_EOuO8a3KVqUeeI3SSHY"
                          className="fa fa-linkedin"
                        >
                          {" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href=" https://www.instagram.com/fizakcontact/"
                          className="fa fa-instagram"
                        >
                          {" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 col-md-8">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Liens Utiles</h3>
                    <ul>
                      <li>
                        <NavLink to="/about-us">À propos</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-us">Contactez-nous</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Produits</h3>
                    <ul>
                      <li>
                        <NavLink to="/product/citrus">Agrumes</NavLink>
                      </li>
                      <li>
                        <NavLink to="/product/tomatoes">Tomates</NavLink>
                      </li>
                      <li>
                        <NavLink to="/product/fraise">Fruits tendres</NavLink>
                      </li>
                      <li>
                        <NavLink to="/product/fruit">Autres fruits</NavLink>
                      </li>
                      <li>
                        <NavLink to="/product/vegetable">Légumes</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom">
            <div className="container">
              <div className="footer-bottom-info">
                <div className="footer-copy-right">
                  <span className="copyrights-text">
                    Copyright © 2023 by FIZAK, Created by{" "}
                    <a href="https://www.expert-sud.ma">Expert Sud</a>. All
                    Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
