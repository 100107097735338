import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { publicUrlFor } from "../../../globals/constants";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

function Header({ toggleLanguage, currentLanguage }) {
  const [isActive, setIsActive] = useState(false);

  function toggleNavClass() {
    setIsActive(!isActive);
  }

  return (
    <header
      className={
        "site-header header-style-3 mobile-sider-drawer-menu " +
        (isActive ? "active" : "")
      }
    >
      <div className="sticky-header main-bar-wraper  navbar-expand-lg">
        <div className="main-bar">
          <div className="container-fluid clearfix">
            <div className="logo-header">
              <div className="logo-header-inner logo-header-one">
                <NavLink to="/">
                  <img
                    src={publicUrlFor("/assets/images/logo-dark.png")}
                    alt="#"
                  />
                </NavLink>
              </div>
            </div>

            {/* NAV Toggle Button */}
            <button
              id="mobile-side-drawer"
              data-target=".header-nav"
              data-toggle="collapse"
              className="navbar-toggler collapsed"
              onClick={toggleNavClass}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar icon-bar-first"></span>
              <span className="icon-bar icon-bar-two"></span>
              <span className="icon-bar icon-bar-three"></span>
            </button>

            {/* MAIN Vav */}
            <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
              <ul className=" nav navbar-nav">
                <li className="has-child">
                  {" "}
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li className="has-child">
                  {" "}
                  <Link to="/about-us" className="nav-link">
                    About Us
                  </Link>
                </li>

                <li className="has-child">
                  <NavLink href="#"> Products </NavLink>
                  <ul className="sub-menu">
                    <li>
                      <NavLink to="/product/citrus">Citrus</NavLink>
                    </li>
                    <li>
                      <NavLink to="/product/tomatoes">Tomatoes</NavLink>
                    </li>
                    <li>
                      <NavLink to="/product/fraise"> Soft Fruit </NavLink>
                    </li>
                    <li>
                      <NavLink to="/product/fruit"> Other Fruits </NavLink>
                    </li>
                    <li>
                      <NavLink to="/product/vegetable"> Vegetable </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="has-child">
                  {" "}
                  <Link to="/faq" className="nav-link">
                    FAQ
                  </Link>
                </li>

                <li className="has-child">
                  <Link to="/contact-us">Contact</Link>
                </li>
                <li className="has-child">
                  <NavLink href="#"> Languages </NavLink>
                  <ul className="sub-menu">
                    <li>
                      <NavLink to="#" onClick={toggleLanguage}>
                        French
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#" onClick={toggleLanguage}>
                        English
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            {/* Header Right Section */}
            <div className="extra-nav header-2-nav">
              <div className="extra-cell">
                {/* <div className="header-search">
                                    <a href="#search" className="header-search-icon"><i class="fa-thin fa-globe"></i></a>
                                </div> */}
              </div>
              {/* <div className="extra-cell">
                                <div className="header-search">
                                    <a href="#search" className="header-search-icon"><i className="fa fa-search"></i> </a>
                                </div>
                            </div> */}
              <div className="extra-cell">
                <div className="header-nav-call-section">
                  <div className="detail">
                    <span className="title">Call US</span>
                    <span>
                      <a href="tel:29002344241">(+31) 611 361 785</a>
                    </span>
                  </div>
                  <div className="media">
                    <img
                      src={publicUrlFor("/assets/images/call.png")}
                      alt="#"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SITE Search */}
        {/* <div id="search">
                    <span className="close"></span>
                    <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
                        <input className="form-control" name="q" type="search" placeholder="Type to search" />
                        <span className="input-group-append">
                            <button type="button" className="search-btn">
                                <i className="fa fa-paper-plane"></i>
                            </button>
                        </span>
                    </form>
                </div> */}
      </div>
    </header>
  );
}

export default Header;
