const publicUrl = process.env.PUBLIC_URL
const baseUrl = publicUrl
const appImages = baseUrl + "/assets/images/products/"
export const data = {
    citrus: {
        title: "Agrumes",
        icon: appImages + "orange.png",
        icon_date: appImages + "orangedate.png",
        image: appImages + "orange.jpg",
        first_paragraph: "Au sein de notre plateforme d'importation et d'exportation d'agrumes marocains, nous nous consacrons à vous fournir les agrumes les plus frais et les plus exquis que le sol marocain généreux a à offrir. Forts d'une riche histoire dans la culture des agrumes et d'un engagement inébranlable envers la qualité, nous sommes votre partenaire de confiance dans le commerce mondial des agrumes. Que vous soyez un amateur d'agrumes, un détaillant ou un distributeur, nous disposons d'une vaste gamme de produits et de services conçus pour répondre à vos besoins spécifiques.",
        seconde_paragraph: "",
        products: [
          
            {
                title: "Citron",
                availability: [true, true, true, true, true, true, false, false, false, false, true, true],
            },

            {
                title: "Clémentine",
                availability: [true, true, false, false, false, false, false, false, false, false, true, true],
            },

            {
                title: "Mandarine AFOURAR",
                availability: [true, true, true, false, false, false, false, false, false, false, false, false],
            },

            {
                title: "Orange Navel",
                availability: [false, false, true, true, true, true, false, false, false, false, false, false],
            },
            {
                title: "Orange du Maroc Tardives",
                availability: [false, false, false, false, true, true, true, true, false, false, false, false],
            },           
        ]
    },
    vegetable: {
        title: "Légumes",
        icon: appImages + "poivre-vert.png",
        icon_date: appImages + "poivre-vert.png",
        image: appImages + "vegetable.jpg",
        first_paragraph: "Chez FIZAK, nous avons à cœur de vous offrir une variété exceptionnelle de légumes de qualité supérieure. Nos légumes frais sont cultivés avec soin dans les riches terres marocaines, baignées de soleil méditerranéen. Notre engagement envers la qualité et la durabilité signifie que chaque légume que nous proposons est un exemple de fraîcheur, de goût et de valeur nutritive. Que vous soyez un détaillant, un grossiste ou un distributeur, nos légumes sont le choix idéal pour satisfaire les palais les plus exigeants. De la croquante laitue aux délicieuses carottes, en passant par les poivrons juteux et les aubergines savoureuses, nous avons une gamme diversifiée de légumes qui ajouteront une touche de fraîcheur et de vitalité à chaque plat. Explorez notre sélection de légumes marocains de première qualité avec FIZAK et découvrez l'excellence gustative à chaque bouchée.",
        seconde_paragraph: "",
        products: [
          

            {
                title: "Poivron doux",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },

            {
                title: "Haricot plat",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },

            {
                title: "Courgette blanche / noire",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },
            {
                title: "Aubergine",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },  
            {
                title: "Piment Vert/Rouge Long",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },  
            {
                title: "Piment Jaune Corno Blanco",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },  
            {
                title: "Piment Rouge/Vert Fort",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            }, 
            {
                title: "Petits pois",
                availability: [false, false, false, false, false,false, false, false, false, true, true, true],
            },  
            {
                title: "Concombre",
                availability: [true, true, true, false, false,false, false, false, false, true, true, true],
            }, 
            {
                title: "Courgette",
                availability: [false, false, true, true, true,true, true, true, false, false, false, false],
            },                
        ]
    },
    tomatoes: {
        title: "Tomates",
        icon: appImages + "tomate.png",
        icon_date: appImages + "tomate.png",
        image: appImages + "tomate.jpg",
        first_paragraph: "Chez FIZAK, nous sommes fiers de vous proposer une gamme variée de produits de haute qualité , et l'un de nos produits phares est la tomate fraîche et succulente. Le Maroc est réputé pour sa production agricole exceptionnelle, et nos tomates ne font pas exception. Cultivées sous le soleil méditerranéen et choyées par des agriculteurs locaux expérimentés, nos tomates débordent de saveur, sont d'une couleur vibrante et regorgent de nutriments essentiels. Que vous soyez grossiste, distributeur ou détaillant, nos tomates sont un ingrédient polyvalent qui peut sublimer l'expérience culinaire de vos clients. Des sauces pasta classiques aux salades rafraîchissantes, nos tomates haut de gamme ne répondront pas seulement à vos attentes, elles les dépasseront. Explorez le monde des tomates marocaines avec FIZAK et découvrez le goût de l'excellence à chaque bouchée.",
        seconde_paragraph: "",
        products: [
          
            {
                title: "Tomates rondes",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },
            {
                title: "Tomate cerise",
                availability: [true, true, true, true, false,false, false, false, true, true, true, true],
            },  
                      
        ]
    },
    fraise: {
        title: "Fruits tendres",
        icon: appImages + "fraise.png",
        icon_date: appImages + "fraise.png",
        image: appImages + "fraise.jpg",
        first_paragraph: "Chez FIZAK, nous sommes spécialisés dans la présentation d'une délicieuse variété de fruits tendres, soigneusement sélectionnés dans les vergers luxuriants du Maroc, où le climat méditerranéen confère une saveur unique et délicieuse à chaque fruit. Notre engagement envers la qualité se reflète dans chaque baie sucrée et juteuse ainsi que dans chaque fruit mûri que nous proposons. Que vous soyez un détaillant, un grossiste ou un distributeur, nos fruits tendres sont votre passeport pour enrichir votre gamme de produits avec les trésors sucrés de la nature. Des fraises vibrantes qui explosent de bonté estivale aux abricots riches et ensoleillés qui offrent un goût de la Méditerranée, nos fruits tendres incarnent la fraîcheur et la succulence. Rehaussez vos créations culinaires et séduisez vos clients avec le charme irrésistible des fruits tendres marocains, disponibles chez FIZAK.",
        seconde_paragraph: "",
        products: [
          
            {
                title: "Fraise",
                availability: [true, true, true, true, true,true, false, false, false, false, true, true],
            },
            {
                title: "Framboise",
                availability: [true, true, true, true, true,true, false, false, false, false, true, true],
            }, 
            {
                title: "Mûre",
                availability: [false, false, false, true, true,true, false, false, false, false, false, false],
            },
            {
                title: "Myrtille",
                availability: [true, true, true, true, true,true, true, false, false, false, false, false],
            },  
                      
        ]
    },
    fruit: {
        title: "Autres fruits",
        icon: appImages + "fruit.png",
        icon_date: appImages + "fruit.png",
        image: appImages + "fruit.jpg",
        first_paragraph: "Chez FIZAK, nous sommes passionnés par l'offre d'un assortiment séduisant de fruits frais qui incarnent l'essence de la douceur et de la vitalité de la nature. Notre sélection de fruits est une symphonie de saveurs et de couleurs, soigneusement sourcée et cueillie à la main pour garantir un goût et une qualité inégalés. De la fraîcheur désaltérante d'une pastèque mûre à la disposition ensoleillée d'un melon jaune doré, nos fruits incarnent l'essence même de la fraîcheur et de la bonté naturelle. Explorez notre abondante collection et rehaussez vos créations culinaires avec l'attrait irrésistible des fruits frais venus des quatre coins du globe.",
        seconde_paragraph: "",
        products: [
          
            {
                title: "Pastèque",
                availability: [false, false, true, true, true,true, true, true, false, false, false, false],
            },
            {
                title: "Melon jaune",
                availability: [false, false, true, true, true,true, true, true, false, false, false, false],
            }, 
                                 
        ]
    },
}
