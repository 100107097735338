import BannerFr from "../../sections/common/banner-fr";
import SectionServiceDetailFr from "../../sections/services/section-service-detail-fr";
import SectionSidebarFr from "../../sections/sidebar/section-sidebar-fr";
import { data } from "../../../globals/data/services-details-fr";

export default function ServicesDetailPageFr() {
    return (
        <>
            <BannerFr _data={data.banner} />

            <div className="section-full p-t120 p-b90 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">

                            <SectionServiceDetailFr />

                        </div>
                        <div className="col-lg-4 col-md-12 rightSidebar side-bar">

                            <SectionSidebarFr />

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}