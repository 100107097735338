import SectionBookServiceFr from "./section-book-service-fr"
import SectionServiceContentFr from "./section-service-content-fr"

export default function SectionServiceDetailFr() {
    return (
        <>
            <div className="service-full-info">
                <div className="services-etc m-b30">
                    
                    <SectionServiceContentFr />
                    <SectionBookServiceFr />

                </div>
            </div>
        </>
    )
}